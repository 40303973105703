import React from 'react'
import { Box } from 'theme-ui'
import { HeaderIcon } from '../Header/Header.Icon'

const styles = {
  logo: {
    textAlign: 'center',
    pb: 1,
    mb: 1,
    mt: [3, 0]
  },
  copyright: {
    textAlign: 'center',
    pt: 2,
    mb: [1, 2]
  }
}

export const FooterIcon = () => (
  <>
    <Box sx={styles.logo}>
      <HeaderIcon />
    </Box>
    <Box sx={styles.copyright}>© {new Date().getFullYear()}, Viadog</Box>
  </>
)
