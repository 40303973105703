import common from './common'

export default {
  ...common.button,
  color: `omegaDark`,
  bg: `white`,
  borderColor: `omega`,
  ':hover': {
    borderColor: `alpha`
  }
}
