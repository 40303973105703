import React from 'react'
import { Global } from '@emotion/core'
import { Flex, Box } from 'theme-ui'
import pageContextProvider from '@helpers/pageContextProvider'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'

const bodyStyles = {
  backgroundImage:
    'linear-gradient(134deg, rgba(1, 1, 1,0.02) 0%, rgba(1, 1, 1,0.02) 16%,transparent 16%, transparent 76%,rgba(58, 58, 58,0.02) 76%, rgba(58, 58, 58,0.02) 100%),linear-gradient(215deg, rgba(166, 166, 166,0.02) 0%, rgba(166, 166, 166,0.02) 33%,transparent 33%, transparent 79%,rgba(111, 111, 111,0.02) 79%, rgba(111, 111, 111,0.02) 100%),linear-gradient(303deg, rgba(215, 215, 215,0.02) 0%, rgba(215, 215, 215,0.02) 7%,transparent 7%, transparent 90%,rgba(192, 192, 192,0.02) 90%, rgba(192, 192, 192,0.02) 100%),linear-gradient(302deg, rgba(113, 113, 113,0.02) 0%, rgba(113, 113, 113,0.02) 34%,transparent 34%, transparent 73%,rgba(65, 65, 65,0.02) 73%, rgba(65, 65, 65,0.02) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));',
  pb: [5, 6]
}

const Layout = ({ children, pageContext, location }) => (
  <pageContextProvider.Provider value={{ pageContext, location }}>
    <Flex variant='layout.layout'>
      <Global styles={theme => theme.global(theme)} />
      <Header />
      <Box variant='layout.body' sx={bodyStyles}>
        {children}
      </Box>
      <Footer />
    </Flex>
  </pageContextProvider.Provider>
)

export default Layout
