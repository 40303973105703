import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
// import Logo from '@components/Logo'
// import useSiteMetadata from '@helpers/useSiteMetadata'
import { Link } from 'theme-ui'
import { Link as GLink } from 'gatsby'
import SVG from 'react-inlinesvg'
import viadogSVG from '../../assets/viadog-icon.svg'

export const HeaderIcon = ({ ...props }) => {
  // const { title } = useSiteMetadata()

  // const { logo } = useStaticQuery(logoQuery)
  // const { fixed } = logo && logo.childImageSharp

  const styles = {
    link: {
      display: `inline-flex`,
      color: `heading`,
      fontWeight: `medium`,
      ':visited': {
        color: `heading`
      },
      svg: {
        height: 30
        // m: 'auto',
        // ml: 2
      }
    }
  }

  return (
    <Link variant='mute' title='Viadog' sx={styles.link} as={GLink} to='/'>
      {viadogSVG && <SVG src={viadogSVG} />}
    </Link>
  )

  // return fixed ? <Logo fixed={fixed} title={title} {...props} /> : null
}

// const logoQuery = graphql`
//   query LogoQueryViadog {
//     logo: file(absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }) {
//       childImageSharp {
//         fixed(width: 150, quality: 100) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//   }
// `
